




import Vue from "vue";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { mapGetters } from "vuex";
import { USER_PROFILE } from "@/store/modules/auth/constants";
export default Vue.extend({
  name: "UserDesignation",
  data() {
    return {
      designation: ""
    };
  },
  props: {
    own: {
      type: Boolean,
      default: false
    },
    user_profile: {
      required: true
    }
  },
  computed: {
    ...mapGetters("auth", {
      profile: USER_PROFILE
    })
  },
  created() {
    if (this.user_profile) {
      const profile = this.user_profile as Candidates.Profile;
      this.fetch_latest_work(profile.work_history_detailed);
    }
  },
  watch: {
    profile(new_value: Candidates.CandidateDetails) {
      if (new_value && new_value.profile && this.own) {
        this.fetch_latest_work(new_value.profile.work_history_detailed);
      }
    }
  },
  methods: {
    fetch_latest_work(word_details: Candidates.WorkHistoryDetailed) {
      const work_details: Candidates.WorkHistoryDetails[] =
        Object.values(word_details);
      // If work history details exist
      if (work_details.length > 0) {
        const latest_work: Candidates.WorkHistoryDetails =
          work_details[0] as Candidates.WorkHistoryDetails;
        const org = latest_work.organization;
        // If org exist
        if (org)
          this.designation = `${work_details[0].job_title} @ ${work_details[0].organization}`;
        else this.designation = `${work_details[0].job_title}`;
      } else this.designation = "";
    }
  }
});
