














































































import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { Candidates } from "@/interfaces/data_objects/candidates";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { get_proficiency_levels_name } from "@/utils/proficiency_levels";

export default Vue.extend({
  name: "ProficiencyReport",
  methods: { get_proficiency_levels_name },
  components: { DataNotFound },
  data() {
    return {};
  },
  props: {
    title: {
      type: String as PropType<TranslateResult>
    },
    assessment: {
      type: Object as PropType<Candidates.Assessment>
    },
    loading: {
      type: Boolean,
      default: false
    },
    card_base: {
      type: Boolean,
      default: true
    }
  }
});
