

























































































































































import Vue, { PropType } from "vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import CrudCertificationDetails from "@/components/shared/view_profile/crud/CrudCertificationDetails.vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { generate_random_key } from "@/utils/global";
import TextWithSeeMore from "@/components/shared/TextWithSeeMore.vue";
import { Candidates } from "@/interfaces/data_objects/candidates";

export default Vue.extend({
  name: "CertificationDetails",
  components: { TextWithSeeMore, CrudCertificationDetails, DataNotFound },
  data() {
    return {
      add_crt_details: false,
      view_crt_details: false,
      edit_crt_details: false,
      remove_cert_details: false,
      add_cert_key: generate_random_key(),
      index: "-1"
    };
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    })
  },
  props: {
    certifications: {
      type: Object as PropType<Candidates.CertificationsDetailed>,
      required: true
    },
    card_base: {
      type: Boolean,
      default: true
    },
    allow_add: {
      type: Boolean,
      default: false
    },
    allow_edit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    disable_add_mode() {
      this.add_crt_details = false;
      this.add_cert_key = generate_random_key();
    },
    getTitle() {
      this.$t("shared.no-certificate");
    },
    enable_edit_mode(index: string) {
      this.index = index;
      this.edit_crt_details = true;
    },
    disable_edit_mode() {
      this.index = "-1";
      this.edit_crt_details = false;
    },
    enable_view_mode(index: string) {
      this.index = index;
      this.view_crt_details = true;
    },
    disable_view_mode() {
      this.index = "-1";
      this.view_crt_details = false;
    },
    enable_delete_mode(index: string) {
      this.index = index;
      this.remove_cert_details = true;
    },
    disable_delete_mode() {
      this.index = "-1";
      this.remove_cert_details = false;
    }
  }
});
