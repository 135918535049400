











































































































































import Vue from "vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import CrudEducationDetails from "@/components/shared/view_profile/crud/CrudEducationDetails.vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { generate_random_key } from "@/utils/global";
import TextWithSeeMore from "@/components/shared/TextWithSeeMore.vue";
export default Vue.extend({
  name: "EducationDetails",
  data() {
    return {
      add_edu_details: false,
      view_edu_details: false,
      edit_edu_details: false,
      remove_edu_details: false,
      add_edu_key: 1,
      index: "-1"
    };
  },
  components: { TextWithSeeMore, CrudEducationDetails, DataNotFound },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    })
  },
  props: {
    education: {
      required: true
    },
    card_base: {
      type: Boolean,
      default: true
    },
    allow_add: {
      type: Boolean,
      default: false
    },
    allow_edit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    generate_random_key,
    disable_add_mode() {
      this.add_edu_details = false;
      this.add_edu_key++;
    },
    getTitle() {
      return this.$t("shared.no-education-data");
    },
    enable_edit_mode(index: string) {
      this.index = index;
      this.edit_edu_details = true;
    },
    disable_edit_mode() {
      this.index = "-1";
      this.edit_edu_details = false;
    },
    enable_delete_mode(index: string) {
      this.index = index;
      this.remove_edu_details = true;
    },
    disable_delete_mode() {
      this.index = "-1";
      this.remove_edu_details = false;
    },
    enable_view_mode(index: string) {
      this.index = index;
      this.view_edu_details = true;
    },
    disable_view_mode() {
      this.index = "-1";
      this.view_edu_details = false;
    }
  }
});
