








































































































































































































































import Vue from "vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import CrudPersonalityDetails from "@/components/shared/view_profile/crud/CrudPersonalityDetails.vue";
import {
  download_file,
  format_date,
  generate_random_key
} from "@/utils/global";
import ByPrincipleUsLogo from "@/components/assessments/ByPrincipleUsLogo.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_ASSESSMENT,
  GET_ASSESSMENT_PDF
} from "@/store/modules/common/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { PrincipleAssessments } from "@/interfaces/data_objects/principle_assessments";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "Personality",
  components: { ByPrincipleUsLogo, CrudPersonalityDetails },
  data() {
    return {
      add_personality_details: false,
      view_personality_details: false,
      edit_personality_details: false,
      remove_personality_details: false,
      add_personality_key: generate_random_key(),
      index: "-1",
      assessment_details: null as PrincipleAssessments | null,
      download_report_loading: false
    };
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  props: {
    personality_details: {
      required: true
    },
    card_base: {
      type: Boolean,
      default: true
    },
    allow_add: {
      type: Boolean,
      default: false
    },
    allow_edit: {
      type: Boolean,
      default: false
    },
    assessment_user_id: {
      type: Number,
      required: true
    }
  },
  async mounted() {
    this.assessment_details = await this.fetch_assessment_by_user(
      this.assessment_user_id
    );
  },
  methods: {
    format_date,
    ...mapActions("common", {
      fetch_assessment_by_user: GET_ASSESSMENT,
      fetch_assessment_pdf: GET_ASSESSMENT_PDF
    }),
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    generate_random_key,
    async get_assessment_report(assessment: PrincipleAssessments) {
      this.download_report_loading = true;
      const url = await this.fetch_assessment_pdf({
        assessment_id: assessment.id,
        user_id: assessment.user_id
      });
      if (!url) this.root_error(this.$t("errors.assessment-report-failed"));
      else if (url.includes("http") || url.includes("https"))
        await download_file(url);
      else this.root_notification(this.$t("success-messages.pdf-creation"));
      this.download_report_loading = false;
    },
    disable_add_mode() {
      this.add_personality_details = false;
      this.add_personality_key = generate_random_key();
    },
    enable_view_mode(index: string) {
      this.index = index;
      this.view_personality_details = true;
    },
    disable_view_mode() {
      this.index = "-1";
      this.view_personality_details = false;
    },
    enable_edit_mode(index: number | string) {
      this.index = index.toString();
      this.edit_personality_details = true;
    },
    disable_edit_mode() {
      this.index = "-1";
      this.edit_personality_details = false;
    },
    enable_delete_mode(index: string | number) {
      this.index = index.toString();
      this.remove_personality_details = true;
    },
    disable_delete_mode() {
      this.index = "-1";
      this.remove_personality_details = false;
    }
  }
});
