



















import Vue, { PropType } from "vue";
import { User } from "@/interfaces/data_objects/user";
import { get_name_initials } from "@/utils/global";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "ProfilePicture",
  data() {
    return {
      name_initials: "",
      data: null as User | null
    };
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    // For using .avatar class
    // By default using .user-details-avatar class
    extra_avatar_class: {
      type: Boolean,
      default: false
    },
    mobile_size: {
      type: Number,
      default: 70
    },
    desktop_size: {
      type: Number,
      default: 140
    },
    own: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // If avatar not exist
    if (!this.user.avatar_uri) {
      // If last name not exist => assign empty string
      const last_name = this.user.last_name ? this.user.last_name : "";
      const name = this.user.first_name + " " + last_name;
      // Get name initials
      this.name_initials = get_name_initials(name);
    } else this.data = this.user;
  },
  computed: {
    ...mapGetters("auth", { get_user_details: GET_USER_DETAILS })
  },
  watch: {
    get_user_details(n) {
      if (n && this.own) {
        this.data = n;
      }
    }
  }
});
