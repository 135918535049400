




















import Vue from "vue";

export default Vue.extend({
  name: "UserRating",

  props: {
    rating: {
      type: Number,
      default: 4.3
    }
  }
});
