













































































































































import Vue, { PropType } from "vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import CrudWorkDetails from "@/components/shared/view_profile/crud/CrudWorkDetails.vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { generate_random_key } from "@/utils/global";
import { Candidates } from "@/interfaces/data_objects/candidates";
import TextWithSeeMore from "@/components/shared/TextWithSeeMore.vue";

export default Vue.extend({
  name: "WorkExperience",
  components: { TextWithSeeMore, CrudWorkDetails, DataNotFound },
  data() {
    return {
      add_work_details: false,
      view_work_details: false,
      edit_work_details: false,
      remove_work_details: false,
      add_work_key: generate_random_key(),
      index: "-1"
    };
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    })
  },
  props: {
    work_details: {
      required: true,
      type: Object as PropType<Candidates.WorkHistoryDetailed>
    },
    card_base: {
      type: Boolean,
      default: true
    },
    allow_add: {
      type: Boolean,
      default: false
    },
    allow_edit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    add_event() {
      // console.log("ADD WorkExperience PRESSED");
    },
    getTitle() {
      return this.$t("shared.no-work-experience");
    },
    disable_add_mode() {
      this.add_work_details = false;
      this.add_work_key = generate_random_key();
    },
    enable_edit_mode(index: string) {
      this.index = index;
      this.edit_work_details = true;
    },
    disable_edit_mode() {
      this.index = "-1";
      this.edit_work_details = false;
    },
    enable_delete_mode(index: string) {
      this.index = index;
      this.remove_work_details = true;
    },
    disable_delete_mode() {
      this.index = "-1";
      this.remove_work_details = false;
    },
    enable_view_mode(index: string) {
      this.index = index;
      this.view_work_details = true;
    },
    disable_view_mode() {
      this.index = "-1";
      this.view_work_details = false;
    }
  }
});
