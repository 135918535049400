









































































import Vue, { PropType } from "vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { mapActions, mapGetters } from "vuex";
import {
  AUTH_LOADING,
  GET_USER_DETAILS,
  REMOVE_HIGHLIGHT_AWARDS,
  UPLOAD_HIGHLIGHT_AWARDS,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import { generate_random_key } from "@/utils/global";
import CrudHighlightsAwards from "@/components/shared/view_profile/crud/CrudHighlightsAwards.vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { RemoveAwardPayload } from "@/store/modules/auth/interfaces";
import { scoreUtils } from "@/utils";

export default Vue.extend({
  name: "HighlightsAwards",
  components: {
    CrudHighlightsAwards,
    DataNotFound
  },
  data() {
    return {
      add_awards: false,
      add_awards_key: generate_random_key(),
      data: null as null | Candidates.Media[]
    };
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS,
      auth_loading: AUTH_LOADING,
      user_profile: USER_PROFILE
    })
  },
  mounted() {
    this.data = this.highlights;
  },
  props: {
    highlights: {
      type: Array as PropType<Candidates.Media[]>,
      required: true
    },
    card_base: {
      type: Boolean,
      default: true
    },
    allow_add: {
      type: Boolean,
      default: false
    },
    own: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    user_profile(n) {
      if (n && n.profile && this.own) {
        this.data = n.profile.media;
      }
    }
  },
  methods: {
    ...mapActions("auth", {
      upload_awards: UPLOAD_HIGHLIGHT_AWARDS,
      remove_highlight_award: REMOVE_HIGHLIGHT_AWARDS
    }),
    disable_add_mode() {
      this.add_awards = false;
      this.add_awards_key = generate_random_key();
    },
    getTitle() {
      return this.$t("shared.no-highlights");
    },
    async remove_award(award: Candidates.Media) {
      const payload: RemoveAwardPayload = {
        id: award.id,
        uid: this.user_profile.info.file_id
      };
      // Removing highlight award
      const response = await this.remove_highlight_award(payload);
      if (response && this.data) {
        this.data = this.data.filter(
          (media: Candidates.Media) => media.id !== award.id
        );
      }
      scoreUtils.clearProfileScore();
    }
  }
});
